import { createContext, JSX, useContext } from "solid-js";

import Log from "@repo/utils/Log";

const ErrorContext = createContext<{ capture(error: string | Error): void }>();

export declare namespace ErrorProvider {
  type Props = {
    onError(error: string | Error): void;
    children: JSX.Element;
  };
}

export function ErrorProvider(props: ErrorProvider.Props) {
  return (
    <ErrorContext.Provider
      value={{
        capture(e) {
          Log.error(e);
          props.onError(e);
        },
      }}
    >
      {props.children}
    </ErrorContext.Provider>
  );
}

export function useError() {
  const context = useContext(ErrorContext);
  if (!context) throw new Error("Called useError outside of ErrorProvider.");

  return context;
}
