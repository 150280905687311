import { JSX } from "solid-js";
import c from "class-c";

import styles from "./CheckAnimation.module.scss";

interface CheckAnimationProps {
  class?: string;
  color?: JSX.CSSProperties["color"];
  size?: JSX.CSSProperties["width"];
  show?: boolean;
}

export default function CheckAnimation({
  class: className,
  size = "1em",
  show = true,
  color = "currentcolor",
}: D<CheckAnimationProps>) {
  return (
    <span class={c`${styles["check-animation"]} ${className}`}>
      <svg viewBox="5 5 40 40" width={size} height={size}>
        {show && (
          <path
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
            style={{ stroke: color }}
          />
        )}
      </svg>
    </span>
  );
}
