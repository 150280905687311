import { createSignal, JSX, onCleanup, onMount, Show } from "solid-js";

declare namespace Delay {
  interface Props {
    for: number;
    children: JSX.Element;
  }
}

function Delay({ for: ms, children }: D<Delay.Props>) {
  const [show, setShow] = createSignal(false);

  onMount(() => {
    const t = setTimeout(() => {
      setShow(true);
    }, ms);
    onCleanup(() => {
      clearTimeout(t);
    });
  });

  return <Show when={show()}>{children}</Show>;
}

export default Delay;
